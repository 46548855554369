import LOGOS from '../../../../../images/logo_icons';

type CardContent = {
  name: string;
  type: 'INCORTA' | 'CDATA';
  cdata?: boolean;
  link: string;
  image?: any;
};

export const dataFile: CardContent[] = [
  {
    name: 'File System',
    type: 'INCORTA',
    link: '/latest/references-connectors-file-system/',
    image: LOGOS.upload,
  },
];

export const application: CardContent[] = [
  {
    name: 'Autoline',
    type: 'CDATA',
    link: '/latest/references-connectors-autoline/',
    image: LOGOS.autoline,
  },
  {
    name: 'DynamoDB',
    type: 'INCORTA',
    link: '/latest/references-connectors-amazon-web-services-aws-dynamodb/',
    image: LOGOS.dynamoDB,
  },
  {
    name: 'Cisco Meraki',
    type: 'INCORTA',
    link: '/latest/references-connectors-cisco-meraki/',
    image: LOGOS.ciscoMeraki,
  },
  {
    name: 'Freshservice',
    type: 'INCORTA',
    link: '/latest/references-connectors-freshservice/',
    image: LOGOS.freshservice,
  },
  {
    name: 'Jira',
    type: 'CDATA',
    link: '/latest/references-connectors-jira/',
    image: LOGOS.jira,
  },
  {
    name: 'Saved Searches',
    type: 'INCORTA',
    link: '/latest/references-connectors-netsuite-saved-searches/',
    image: LOGOS.netsuite,
  },
  {
    name: 'SuiteAnalytics',
    type: 'INCORTA',
    link: '/latest/references-connectors-netsuite-suiteanalytics/',
    image: LOGOS.netsuite,
  },
  {
    name: 'SuiteQL',
    type: 'INCORTA',
    link: '/latest/references-connectors-netsuite-suiteql/',
    image: LOGOS.netsuite,
  },
  {
    name: 'Web Service',
    type: 'INCORTA',
    link: '/latest/netsuite-webservice-connector/',
    image: LOGOS.netsuite,
  },
  {
    name: 'B2C Service',
    type: 'INCORTA',
    link: '/latest/references-connectors-oracle-b2c-service/',
    image: LOGOS.oracle,
  },
  {
    name: 'BI Publisher',
    type: 'INCORTA',
    link: '/latest/references-connectors-oracle-bi-publisher/',
    image: LOGOS.oracle,
  },
  {
    name: 'Cloud Applications (UCM)',
    type: 'INCORTA',
    link: '/latest/references-connectors-oracle-cloud-applications-ucm/',
    image: LOGOS.oracle,
  },
  {
    name: 'Cloud Applications',
    type: 'INCORTA',
    link: '/latest/references-connectors-oracle-cloud-applications/',
    image: LOGOS.oracle,
  },
  {
    name: 'Essbase',
    type: 'INCORTA',
    link: '/latest/references-connectors-oracle-essbase/',
    image: LOGOS.oracle,
  },
  {
    name: 'Sales Cloud',
    type: 'CDATA',
    link: '/latest/references-connectors-oracle-sales-cloud/',
    image: LOGOS.oracle,
  },
  {
    name: 'Warehouse Management System',
    type: 'INCORTA',
    link: '/latest/references-connectors-oracle-wms/',
    image: LOGOS.oracle,
  },
  {
    name: 'CPQ',
    type: 'INCORTA',
    link: '/latest/references-connectors-oracle-cpq/',
    image: LOGOS.oracleCPQ,
  },
  {
    name: 'OTM/GTM',
    type: 'INCORTA',
    link: '/latest/references-connectors-oracle-otm-gtm/',
    image: LOGOS.oracleOTM,
  },
  {
    name: 'Salesforce v1',
    type: 'INCORTA',
    link: '/latest/references-connectors-salesforce-v1/',
    image: LOGOS.salesforce,
  },
  {
    name: 'Salesforce v2',
    type: 'INCORTA',
    link: '/latest/references-connectors-salesforce-v2/',
    image: LOGOS.salesforce,
  },
  {
    name: 'SAP ERP',
    type: 'INCORTA',
    link: '/latest/references-connectors-sap-erp/',
    image: LOGOS.sap,
  },
  {
    name: 'ServiceNow',
    type: 'INCORTA',
    link: '/latest/references-connectors-servicenow/',
    image: LOGOS.serviceNow,
  },
  {
    name: 'Smartsheet',
    type: 'CDATA',
    link: '/latest/references-connectors-smartsheet/',
    image: LOGOS.smartsheet,
  },
  {
    name: 'Splunk',
    type: 'INCORTA',
    link: '/latest/references-connectors-splunk/',
    image: LOGOS.splunk,
  },
  {
    name: 'Zuora',
    type: 'INCORTA',
    link: '/latest/references-connectors-zuora/',
    image: LOGOS.zuora,
  },
];

export const database: CardContent[] = [
  {
    name: 'AlloyDB',
    type: 'INCORTA',
    link: '/latest/references-connectors-alloydb/',
    image: LOGOS.alloyDB,
  },
  {
    name: 'Apache Hive',
    type: 'INCORTA',
    link: '/latest/references-connectors-apache-hive/',
    image: LOGOS.apacheHive,
  },
  {
    name: 'IBM DB2',
    type: 'INCORTA',
    link: '/latest/references-connectors-ibm-db2/',
    image: LOGOS.ibm,
  },
  {
    name: 'JD Edwards',
    type: 'INCORTA',
    link: '/latest/references-connectors-jd-edwards/',
    image: LOGOS.jdedwards,
  },
  {
    name: 'Kyuubi',
    type: 'INCORTA',
    link: '/latest/references-connectors-kyuubi/',
    image: LOGOS.kyuubi,
  },
  {
    name: 'MariaDB',
    type: 'INCORTA',
    link: '/latest/references-connectors-mariadb/',
    image: LOGOS.mariadb,
  },
  {
    name: 'MongoDB BI',
    type: 'INCORTA',
    link: '/latest/references-connectors-mongodb-bi/',
    image: LOGOS.mongoDB,
  },
  {
    name: 'MySQL',
    type: 'INCORTA',
    link: '/latest/references-connectors-mysql/',
    image: LOGOS.mySQL,
  },
  {
    name: 'Netezza',
    type: 'INCORTA',
    link: '/latest/r-ibm-netezza-connector/',
    image: LOGOS.ibm,
  },
  {
    name: 'Oracle',
    type: 'INCORTA',
    link: '/latest/references-connectors-oracle/',
    image: LOGOS.oracle,
  },
  {
    name: 'EPM',
    type: 'INCORTA',
    link: '/latest/references-connectors-oracle-epm/',
    image: LOGOS.oracle,
  },
  {
    name: 'PostgreSQL',
    type: 'INCORTA',
    link: '/latest/references-connectors-postgresql/',
    image: LOGOS.postgreSQL,
  },
  {
    name: 'Redshift',
    type: 'INCORTA',
    link: '/latest/references-connectors-amazon-web-services-aws-redshift/',
    image: LOGOS.redshift,
  },
  {
    name: 'HANA',
    type: 'INCORTA',
    link: '/latest/references-connectors-sap-hana/',
    image: LOGOS.sap,
  },
  {
    name: 'Sybase IQ',
    type: 'INCORTA',
    link: '/latest/r-sap-sybase-iq-connector/',
    image: LOGOS.sap,
  },
  {
    name: 'Snowflake',
    type: 'INCORTA',
    link: '/latest/references-connectors-snowflake/',
    image: LOGOS.snowflake,
  },
  {
    name: 'Microsoft SQL Server',
    type: 'INCORTA',
    link: '/latest/references-connectors-microsoft-sql-server/',
    image: LOGOS.microsoftSQLServer,
  },
  {
    name: 'Microsoft SQL Server (jTDS)',
    type: 'INCORTA',
    link: '/latest/references-connectors-microsoft-sql-server-jtds/',
    image: LOGOS.microsoftSQLServer,
  },
  {
    name: 'Teradata',
    type: 'INCORTA',
    link: '/latest/r-teradata-connector/',
    image: LOGOS.teradata,
  },
  {
    name: 'Vertica',
    type: 'INCORTA',
    link: '/latest/r-vertica-connector/',
    image: LOGOS.vertica,
  },
];

export const fileSystem: CardContent[] = [
  {
    name: 'Box',
    type: 'INCORTA',
    link: '/latest/references-connectors-box/',
    image: LOGOS.box,
  },
  {
    name: 'Dropbox',
    type: 'INCORTA',
    link: '/latest/references-connectors-dropbox/',
    image: LOGOS.dropbox,
  },
  {
    name: 'Google Drive',
    type: 'INCORTA',
    link: '/latest/references-connectors-google-drive/',
    image: LOGOS.googleDrive,
  },
  {
    name: 'JSON',
    type: 'CDATA',
    link: '/latest/references-connectors-json/',
    image: LOGOS.json,
  },
  {
    name: 'OneLogin',
    type: 'INCORTA',
    link: '/latest/references-connectors-onelogin/',
    image: LOGOS.onelogin,
  },
  {
    name: 'OneDrive',
    type: 'INCORTA',
    link: '/latest/references-connectors-microsoft-onedrive/',
    image: LOGOS.msonedrive,
  },
  {
    name: 'Microsoft SharePoint',
    type: 'INCORTA',
    link: '/latest/references-connectors-microsoft-sharepoint/',
    image: LOGOS.microsoftSharepoint,
  },
  {
    name: 'XML',
    type: 'CDATA',
    link: '/latest/references-connectors-xml/',
    image: LOGOS.upload,
  },
];
export const queryService: CardContent[] = [
  {
    name: 'Athena',
    type: 'INCORTA',
    link: '/latest/references-connectors-amazon-web-services-aws-athena/',
    image: LOGOS.athena,
  },
  {
    name: 'Apache Drill',
    type: 'INCORTA',
    link: '/latest/references-connectors-apache-drill/',
    image: LOGOS.apacheDrill,
  },
  {
    name: 'Google BigQuery',
    type: 'INCORTA',
    link: '/latest/references-connectors-google-bigquery/',
    image: LOGOS.googleBigQuery,
  },
  {
    name: 'Cassandra',
    type: 'INCORTA',
    link: '/latest/references-connectors-cassandra/',
    image: LOGOS.cassandra,
  },
  {
    name: 'Cassandra (Simba)',
    type: 'INCORTA',
    link: '/latest/references-connectors-cassandra-simba/',
    image: LOGOS.cassandra,
  },
  {
    name: 'Azure Cosmos DB',
    type: 'CDATA',
    link: '/latest/references-connectors-cosmos-db/',
    image: LOGOS.cosmosDB,
  },
  {
    name: 'Presto',
    type: 'INCORTA',
    link: '/latest/references-connectors-presto/',
    image: LOGOS.presto,
  },
  {
    name: 'GraphQL',
    type: 'CDATA',
    link: '/latest/references-connectors-graphql/',
    image: LOGOS.graphQL,
  },
];
export const dataLake: CardContent[] = [
  {
    name: 'S3',
    type: 'INCORTA',
    link: '/latest/references-connectors-amazon-web-services-aws-s3/',
    image: LOGOS.aws,
  },
  {
    name: 'Microsoft Azure Gen2',
    type: 'INCORTA',
    link: '/latest/references-connectors-microsoft-azure-gen2/',
    image: LOGOS.microsoftAzureGen2,
  },
  {
    name: 'Apache Hadoop',
    type: 'INCORTA',
    link: '/latest/references-connectors-apache-hadoop/',
    image: LOGOS.hdfs,
  },
  {
    name: 'Local Files',
    type: 'INCORTA',
    link: '/latest/references-connectors-local-files/',
    image: LOGOS.file,
  },
  {
    name: 'FTP',
    type: 'INCORTA',
    link: '/latest/references-connectors-ftp/',
    image: LOGOS.ftp,
  },
  {
    name: 'SFTP',
    type: 'INCORTA',
    link: '/latest/references-connectors-sftp/',
    image: LOGOS.sftp,
  },
  {
    name: 'Google Cloud Storage',
    type: 'INCORTA',
    link: '/latest/references-connectors-google-cloud-storage-gcs/',
    image: LOGOS.googleCloudStorage,
  },
];
export const custom: CardContent[] = [
  {
    name: 'Custom CData',
    type: 'CDATA',
    link: '/latest/references-connectors-custom-cdata/',
    image: LOGOS.cdata,
  },
  {
    name: 'Custom SQL',
    type: 'INCORTA',
    link: '/latest/references-connectors-custom-sql/',
    image: LOGOS.customSql,
  },
];
export const streamingData: CardContent[] = [
  {
    name: 'Apache Kafka',
    type: 'INCORTA',
    link: '/latest/references-connectors-apache-kafka/',
    image: LOGOS.apacheKafka,
  },
  {
    name: 'Apache Kafka V2',
    type: 'INCORTA',
    link: '/latest/references-connectors-apache-kafka-v2/',
    image: LOGOS.apacheKafka,
  },
];
export const other: CardContent[] = [
  {
    name: 'Google Sheets',
    type: 'INCORTA',
    link: '/latest/references-connectors-google-sheets/',
    image: LOGOS.googleSheets,
  },
];
