const tocCloud = [
  {
    name: 'Home',
    link: '/cloud',
  },
  {
    name: 'Guides',
    link: '/cloud/guides-all',
    children: [
      {
        name: 'Start',
        link: '/cloud/guides-start',
      },
      {
        name: 'Analyze',
        link: '/cloud/guides-analyze',
      },
      {
        name: 'Migrate',
        link: '/cloud/guides-migrate',
      },
      {
        name: 'Secure',
        link: '/cloud/guides-secure',
      },
      {
        name: 'Configure SSO',
        link: '/cloud/guides-single-sign-on-configurations',
        children: [
          {
            name: 'Azure AD SSO',
            link: '/cloud/guides-azure-ad-sso-configuration',
          },
          {
            name: 'ADFS SSO',
            link: '/cloud/guides-adfs-sso-configuration',
          },
          {
            name: 'Auth0 SSO',
            link: '/cloud/guides-auth0-sso-configuration',
          },
          {
            name: 'IBM CIS SSO',
            link: '/cloud/guides-ibm-cis-sso-configuration',
          },
          {
            name: 'Okta SSO',
            link: '/cloud/guides-okta-sso-configuration',
          },
          {
            name: 'OneLogin SSO',
            link: '/cloud/guides-onelogin-sso-configuration',
          },
        ],
      },
      {
        name: 'Configure Server',
        link: '/cloud/guides-configure-server',
      },
      {
        name: 'Configure Tenants',
        link: '/cloud/guides-configure-tenants',
      },
      {
        name: 'Organize',
        link: '/cloud/guides-organize-content',
      },
      {
        name: 'Visualizations',
        link: '/cloud/guides-visualizations',
      },
    ],
  },
  {
    name: 'Tours',
    link: '/cloud/tours-all',
    children: [
      {
        name: 'Incorta Tools',
        link: '/cloud/tours-incorta-tools',
      },
      {
        name: 'Content Manager',
        link: '/cloud/tours-content-manager',
      },
    ],
  },
  {
    name: 'Concepts',
    link: '/cloud/concepts-all',
    children: [
      {
        name: 'Aggregate filter',
        link: '/cloud/concepts-aggregate-filter',
      },
      {
        name: 'Alias',
        link: '/cloud/concepts-alias',
      },
      {
        name: 'Apache Avro™',
        link: '/cloud/concepts-apache-avro',
      },
      {
        name: 'Apache Kafka™',
        link: '/cloud/concepts-apache-kafka',
      },
      {
        name: 'Applied filter',
        link: '/cloud/concepts-applied-filter',
      },
      {
        name: 'Base table',
        link: '/cloud/concepts-base-table',
      },
      {
        name: 'Bookmark',
        link: '/cloud/concepts-bookmark',
      },
      {
        name: 'Business schema',
        link: '/cloud/concepts-business-schema',
      },
      {
        name: 'Business schema view',
        link: '/cloud/concepts-business-schema-view',
      },
      {
        name: 'Business schema view column',
        link: '/cloud/concepts-business-schema-view-column',
      },
      {
        name: 'Business schema view formula column',
        link: '/cloud/concepts-business-schema-view-formula-column',
      },
      {
        name: 'Column',
        link: '/cloud/concepts-column',
      },
      {
        name: 'Connector',
        link: '/cloud/concepts-connector',
      },
      {
        name: 'Dashboard filter',
        link: '/cloud/concepts-dashboard-filter',
      },
      {
        name: 'Dashboard folder',
        link: '/cloud/concepts-dashboard-folder',
      },
      {
        name: 'Dashboard runtime filter',
        link: '/cloud/concepts-dashboard-runtime-filter',
      },
      {
        name: 'Data agent',
        link: '/cloud/concepts-data-agent',
      },
      {
        name: 'Data alert',
        link: '/cloud/concepts-data-alert',
      },
      {
        name: 'Data destination',
        link: '/cloud/concepts-data-destination',
      },
      {
        name: 'Date system variable',
        link: '/cloud/concepts-date-system-variable',
      },
      {
        name: 'Distinct filter',
        link: '/cloud/concepts-distinct-filter',
      },
      {
        name: 'Dynamic Field',
        link: '/cloud/concepts-dynamic-field',
      },
      {
        name: 'Expression',
        link: '/cloud/concepts-expression',
      },
      {
        name: 'External data source',
        link: '/cloud/concepts-external-data-source',
      },
      {
        name: 'External session variable',
        link: '/cloud/concepts-external-session-variable',
      },
      {
        name: 'Filter expression',
        link: '/cloud/concepts-filter-expression',
      },
      {
        name: 'Filter expression session variable',
        link: '/cloud/concepts-filter-expression-session-variable',
      },
      {
        name: 'Filter option',
        link: '/cloud/concepts-filter-option',
      },
      {
        name: 'Formula column',
        link: '/cloud/concepts-formula-column',
      },
      {
        name: 'Formula expression',
        link: '/cloud/concepts-formula-expression',
      },
      {
        name: 'Full load',
        link: '/cloud/concepts-full-load',
      },
      {
        name: 'Global variable',
        link: '/cloud/concepts-global-variable',
      },
      {
        name: 'Incorta Analyzer Table',
        link: '/cloud/concepts-incorta-analyzer-table',
      },
      {
        name: 'Incorta Analyzer Table column',
        link: '/cloud/concepts-incorta-analyzer-table-column',
      },
      {
        name: 'Incorta Analyzer Table formula column',
        link: '/cloud/concepts-incorta-analyzer-table-formula-column',
      },
      {
        name: 'Incorta Analyzer View',
        link: '/cloud/concepts-incorta-view',
      },
      {
        name: 'Incorta Analyzer View formula column',
        link: '/cloud/concepts-incorta-view-formula-column',
      },
      {
        name: 'Incorta SQL Table',
        link: '/cloud/concepts-incorta-sql-table',
      },
      {
        name: 'Incorta SQL View',
        link: '/cloud/concepts-incorta-sql-view',
      },
      {
        name: 'Individual filter',
        link: '/cloud/concepts-individual-filter',
      },
      {
        name: 'Insight filter',
        link: '/cloud/concepts-insight-filter',
      },
      {
        name: 'Internal query expression',
        link: '/cloud/concepts-internal-query-expression',
      },
      {
        name: 'Internal session variable',
        link: '/cloud/concepts-internal-session-variable',
      },
      {
        name: 'Join',
        link: '/cloud/concepts-join',
      },
      {
        name: 'Join condition',
        link: '/cloud/concepts-join-condition',
      },
      {
        name: 'Join condition column',
        link: '/cloud/concepts-join-condition-column',
      },
      {
        name: 'Join filter',
        link: '/cloud/concepts-join-filter',
      },
      {
        name: 'Join filter column',
        link: '/cloud/concepts-join-filter-column',
      },
      {
        name: 'Level based measure',
        link: '/cloud/concepts-level-based-measure',
      },
      {
        name: 'Load filter',
        link: '/cloud/concepts-load-filter',
      },
      {
        name: 'Local data file',
        link: '/cloud/concepts-local-data-file',
      },
      {
        name: 'Local data folder',
        link: '/cloud/concepts-local-data-folder',
      },
      {
        name: 'Materialized View',
        link: '/cloud/concepts-materialized-view',
      },
      {
        name: 'Materialized View Column',
        link: '/cloud/concepts-materialized-view-column',
      },
      {
        name: 'Materialized View Formula Column',
        link: '/cloud/concepts-materialized-view-formula-column',
      },
      {
        name: 'Measure filter',
        link: '/cloud/concepts-measure-filter',
      },
      {
        name: 'Miscellaneous system variable',
        link: '/cloud/concepts-miscellaneous-system-variable',
      },
      {
        name: 'Multi-Source table',
        link: '/cloud/concepts-multi-source-table',
      },
      {
        name: 'Physical schema',
        link: '/cloud/concepts-physical-schema',
      },
      {
        name: 'Physical schema table',
        link: '/cloud/concepts-physical-schema-table',
      },
      {
        name: 'Physical schema table column',
        link: '/cloud/concepts-physical-schema-table-column',
      },
      {
        name: 'Physical schema table formula column',
        link: '/cloud/concepts-physical-schema-table-formula-column',
      },
      {
        name: 'Physical schema table runtime security filter',
        link: '/cloud/concepts-physical-schema-table-runtime-security-filter',
      },
      {
        name: 'Presentation variable',
        link: '/cloud/concepts-presentation-variable',
      },
      {
        name: 'Prompt',
        link: '/cloud/concepts-prompt',
      },
      {
        name: 'Query system variable',
        link: '/cloud/concepts-query-system-variable',
      },
      {
        name: 'Runtime business view',
        link: '/cloud/concepts-runtime-business-view',
      },
      {
        name: 'Runtime business view formula column',
        link: '/cloud/concepts-runtime-business-view-formula-column',
      },
      {
        name: 'Runtime security filter',
        link: '/cloud/concepts-runtime-security-filter',
      },
      {
        name: 'Session variable',
        link: '/cloud/concepts-session-variable',
      },
      {
        name: 'Shared Storage',
        link: '/cloud/concepts-shared-storage',
      },
      {
        name: 'Upgrade Readiness',
        link: '/cloud/concepts-upgrade-readiness',
      },
      {
        name: 'Variable',
        link: '/cloud/concepts-variable',
      },
      {
        name: 'Visualization',
        link: '/cloud/concepts-visualization',
      },
    ],
  },
  {
    name: 'Tools',
    link: '/cloud/tools-all',
    children: [
      {
        name: 'Alert Editor',
        link: '/cloud/tools-alert-editor',
      },
      {
        name: 'Analyzer',
        link: '/cloud/tools-analyzer',
      },
      {
        name: 'Avro Extractor Tool',
        link: '/cloud/tools-avro-extractor-tool',
      },
      {
        name: 'Business Schema Designer',
        link: '/cloud/tools-business-schema-designer',
      },
      {
        name: 'Business Schema Manager',
        link: '/cloud/tools-business-schema-manager',
      },
      {
        name: 'CMC Logs Manager',
        link: '/cloud/tools-cmc-logs-manager',
      },
      {
        name: 'CMC Monitoring',
        link: '/cloud/tools-cmc-monitoring',
      },
      {
        name: 'CMC Scheduler',
        link: '/cloud/tools-cmc-scheduler',
      },
      {
        name: 'CMC Tenant Manager',
        link: '/cloud/tools-cmc-tenant-manager',
      },
      {
        name: 'Content Manager',
        link: '/cloud/tools-content-manager',
      },
      {
        name: 'Dashboard Filters Manager',
        link: '/cloud/tools-dashboard-filters-manager',
      },
      {
        name: 'Dashboard Manager',
        link: '/cloud/tools-dashboard-manager',
      },
      {
        name: 'Data Agent',
        link: '/cloud/tools-data-agent',
      },
      {
        name: 'Data Lineage Viewer',
        link: '/cloud/tools-data-lineage-viewer',
      },
      {
        name: 'Data Lineage Viewer v2',
        link: '/cloud/tools-data-lineage-viewer-v2',
      },
      {
        name: 'Data Manager',
        link: '/cloud/tools-data-manager',
      },
      {
        name: 'Formula Builder',
        link: '/cloud/tools-formula-builder',
      },
      {
        name: 'Inspector Tool',
        link: '/cloud/tools-inspector-tool',
      },
      {
        name: 'Join Editor',
        link: '/cloud/tools-join-editor',
      },
      {
        name: 'Load Job Details Viewer',
        link: '/cloud/tools-load-job-details-viewer',
      },
      {
        name: 'Load Job Viewer',
        link: '/cloud/tools-load-job-viewer',
      },
      {
        name: 'Load Plan DAG Viewer',
        link: '/cloud/tools-load-plan-dag-viewer',
      },
      {
        name: 'Model Update Viewer',
        link: '/cloud/tools-model-update-viewer',
      },
      {
        name: 'Notebook Editor',
        link: '/cloud/tools-notebook-editor',
      },
      {
        name: 'Profile Manager',
        link: '/cloud/tools-profile-manager',
      },
      {
        name: 'Public API',
        link: '/cloud/tools-public-api',
      },
      {
        name: 'Query Plan Viewer',
        link: '/cloud/tools-query-plan-viewer',
      },
      {
        name: 'Scheduler',
        link: '/cloud/tools-scheduler',
      },
      {
        name: 'Schema Designer',
        link: '/cloud/tools-schema-designer',
        children: [
          {
            name: 'Schema Designer Joins',
            link: '/cloud/tools-schema-designer-joins',
          },
          {
            name: 'Schema Designer Load Jobs',
            link: '/cloud/tools-schema-designer-load-jobs',
          },
          {
            name: 'Schema Designer Modes and Versions',
            link: '/cloud/tools-schema-designer-modes-and-versions',
          },
          {
            name: 'Schema Designer Tables',
            link: '/cloud/tools-schema-designer-tables',
          },
        ],
      },
      {
        name: 'Schema Diagram Viewer',
        link: '/cloud/tools-schema-diagram-viewer',
      },
      {
        name: 'Schema Manager',
        link: '/cloud/tools-schema-manager',
        children:
        [
          {
            name: 'Schemas',
            link: '/cloud/tools-schema-manager-schemas',
          },
          {
            name: 'Load Jobs',
            link: '/cloud/tools-schema-manager-load-jobs',
          },
          {
            name: 'Session Variables',
            link: '/cloud/tools-schema-manager-session-variables',
          },
          {
            name: 'Global Variables',
            link: '/cloud/tools-schema-manager-global-variables',
          },
        ],
      },
      {
        name: 'Schema Wizard',
        link: '/cloud/tools-schema-wizard',
      },
      {
        name: 'Security Manager',
        link: '/cloud/tools-security-manager',
      },
      {
        name: 'Table Editor',
        link: '/cloud/tools-table-editor',
      },
      {
        name: 'Upload Service Command Line Interface (CLI)',
        link: '/cloud/tools-upload-service-cli',
      },
    ],
  },
  {
    name: 'References',
    link: '/cloud/references-all',
    children: [
      {
        name: 'Built-in Functions',
        link: '/cloud/references-built-in-functions',
        children: [
          {
            name: 'Aggregation Functions',
            link: '/cloud/references-built-in-functions-aggregation',
          },
          {
            name: 'Analytic Functions',
            link: '/cloud/references-built-in-functions-analytic',
          },
          {
            name: 'Boolean Functions',
            link: '/cloud/references-built-in-functions-boolean',
          },
          {
            name: 'Conditional Statements',
            link: '/cloud/references-built-in-functions-conditional',
          },
          {
            name: 'Conversion Functions',
            link: '/cloud/references-built-in-functions-conversion',
          },
          {
            name: 'Filter Functions',
            link: '/cloud/references-built-in-functions-filter',
          },
          {
            name: 'Miscellaneous Functions',
            link: '/cloud/references-built-in-functions-miscellaneous',
          },
          {
            name: 'Arithmetic Functions',
            link: '/cloud/references-built-in-functions-arithmetic',
          },
          {
            name: 'Date Functions',
            link: '/cloud/references-built-in-functions-date',
          },
          {
            name: 'String Functions',
            link: '/cloud/references-built-in-functions-string',
          },
        ],
      },
      {
        name: 'Incorta Component SDK',
        link: '/cloud/references-component-sdk',
        children: [
          {
            name: 'Overview',
            link: '/cloud/references-component-sdk-overview',
          },
          {
            name: 'Getting Started',
            link: '/cloud/references-component-sdk-getting-started',
          },
          {
            name: 'Start Development',
            link: '/cloud/references-component-sdk-start-development',
          },
          {
            name: 'Definition.json',
            link: '/cloud/references-component-sdk-definitions',
          },
          {
            name: 'PrivateData API',
            link: '/cloud/references-component-sdk-privatedata-api',
          },
        ],
      },
      {
        name: 'Incorta Connector SDK',
        link: '/cloud/references-connector-sdk',
      },
      {
        name: 'Connectors',
        link: '/cloud/references-connectors-all',
        children: [
          {
            name: 'AlloyDB',
            link: '/cloud/references-connectors-alloydb',
          },
          {
            name: 'Amazon Web Services (AWS) Athena',
            link: '/cloud/references-connectors-amazon-web-services-aws-athena',
          },
          {
            name: 'Amazon Web Services (AWS) DynamoDB',
            link: '/cloud/references-connectors-amazon-web-services-aws-dynamodb',
          },
          {
            name: 'Amazon Web Services (AWS) RedShift',
            link: '/cloud/references-connectors-amazon-web-services-aws-redshift',
          },
          {
            name: 'Amazon Web Services (AWS) S3',
            link: '/cloud/references-connectors-amazon-web-services-aws-s3',
          },
          {
            name: 'Apache Drill',
            link: '/cloud/references-connectors-apache-drill',
          },
          {
            name: 'Apache Hadoop HDFS',
            link: '/cloud/references-connectors-apache-hadoop',
          },
          {
            name: 'Apache Hive',
            link: '/cloud/references-connectors-apache-hive',
          },
          {
            name: 'Apache Kafka',
            link: '/cloud/references-connectors-apache-kafka',
          },
          {
            name: 'Autoline',
            link: '/cloud/references-connectors-autoline',
          },
          {
            name: 'Box',
            link: '/cloud/references-connectors-box',
          },
          {
            name: 'Cassandra (Simba)',
            link: '/cloud/references-connectors-cassandra-simba',
          },
          {
            name: 'Cassandra (Incorta)',
            link: '/cloud/references-connectors-cassandra',
          },
          {
            name: 'Cisco Meraki',
            link: '/cloud/references-connectors-cisco-meraki',
          },
          {
            name: 'Cosmos DB',
            link: '/cloud/references-connectors-cosmos-db',
          },
          {
            name: 'Custom CData',
            link: '/cloud/references-connectors-custom-cdata',
          },
          {
            name: 'Custom SQL',
            link: '/cloud/references-connectors-custom-sql',
          },
          {
            name: 'Dropbox',
            link: '/cloud/references-connectors-dropbox',
          },
          {
            name: 'File System (LocalFiles)',
            link: '/cloud/references-connectors-file-system',
          },
          {
            name: 'Freshservice',
            link: '/cloud/references-connectors-freshservice',
          },
          {
            name: 'FTP',
            link: '/cloud/references-connectors-ftp',
          },
          {
            name: 'Google BigQuery',
            link: '/cloud/references-connectors-google-bigquery',
          },
          {
            name: 'Google Cloud Storage (GCS)',
            link: '/cloud/references-connectors-google-cloud-storage-gcs',
          },
          {
            name: 'Google Drive',
            link: '/cloud/references-connectors-google-drive',
          },
          {
            name: 'Google Sheets',
            link: '/cloud/references-connectors-google-sheets',
          },
          {
            name: 'GraphQL',
            link: '/cloud/references-connectors-graphql',
          },
          {
            name: 'IBM DB2',
            link: '/cloud/references-connectors-ibm-db2',
          },
          {
            name: 'IBM Netezza',
            link: '/cloud/r-ibm-netezza-connector',
          },
          {
            name: 'JIRA',
            link: '/cloud/references-connectors-jira',
          },
          {
            name: 'JSON',
            link: '/cloud/references-connectors-json',
          },
          {
            name: 'Kyuubi',
            link: '/cloud/references-connectors-kyuubi',
          },
          {
            name: 'Local Files',
            link: '/cloud/references-connectors-local-files',
          },
          {
            name: 'MariaDB',
            link: '/cloud/references-connectors-mariadb',
          },
          {
            name: 'Microsoft Azure Gen2',
            link: '/cloud/references-connectors-microsoft-azure-gen2',
          },
          {
            name: 'Microsoft OneDrive',
            link: '/cloud/references-connectors-microsoft-onedrive',
          },
          {
            name: 'Microsoft SharePoint',
            link: '/cloud/references-connectors-microsoft-sharepoint',
          },
          {
            name: 'Microsoft SQL Server',
            link: '/cloud/references-connectors-microsoft-sql-server',
          },
          {
            name: 'Microsoft SQL Server (JTDS)',
            link: '/cloud/references-connectors-microsoft-sql-server-jtds',
          },
          {
            name: 'MongoDB BI',
            link: '/cloud/references-connectors-mongodb-bi',
          },
          {
            name: 'MySQL',
            link: '/cloud/references-connectors-mysql',
          },
          {
            name: 'NetSuite - Saved Searches',
            link: '/cloud/references-connectors-netsuite-saved-searches',
          },
          {
            name: 'NetSuite - SuiteAnalytics',
            link: '/cloud/references-connectors-netsuite-suiteanalytics',
          },
          {
            name: 'NetSuite - SuiteQL',
            link: '/cloud/references-connectors-netsuite-suiteql',
          },
          {
            name: 'NetSuite - Web Service',
            link: '/cloud/netsuite-webservice-connector',
          },
          {
            name: 'OneLogin',
            link: '/cloud/references-connectors-onelogin',
          },
          {
            name: 'Oracle',
            link: '/cloud/references-connectors-oracle',
          },
          {
            name: 'Oracle B2C Service',
            link: '/cloud/references-connectors-oracle-b2c-service',
          },
          {
            name: 'Oracle Cloud Applications',
            link: '/cloud/references-connectors-oracle-cloud-applications',
          },
          {
            name: 'Oracle Cloud Applications V2',
            link: '/cloud/references-connectors-oracle-cloud-applications-v2',
          },
          {
            name: 'Oracle CPQ',
            link: '/cloud/references-connectors-oracle-cpq',
          },
          {
            name: 'Oracle EPM',
            link: '/cloud/references-connectors-oracle-epm',
          },
          {
            name: 'Oracle Essbase',
            link: '/cloud/references-connectors-oracle-essbase',
          },
          {
            name: 'Oracle OTM/GTM',
            link: '/cloud/references-connectors-oracle-otm-gtm',
          },
          {
            name: 'Oracle Sales Cloud',
            link: '/cloud/references-connectors-oracle-sales-cloud',
          },
          {
            name: 'Oracle WMS',
            link: '/cloud/references-connectors-oracle-wms',
          },
          {
            name: 'PostgreSQL',
            link: '/cloud/references-connectors-postgresql',
          },
          {
            name: 'Presto',
            link: '/cloud/references-connectors-presto',
          },
          {
            name: 'Salesforce v2',
            link: '/cloud/references-connectors-salesforce-v2',
          },
          {
            name: 'SAP ERP',
            link: '/cloud/references-connectors-sap-erp',
          },
          {
            name: 'SAP Hana',
            link: '/cloud/references-connectors-sap-hana',
          },
          {
            name: 'SAP Sybase IQ',
            link: '/cloud/r-sap-sybase-iq-connector',
          },
          {
            name: 'ServiceNow',
            link: '/cloud/references-connectors-servicenow',
          },
          {
            name: 'SFTP',
            link: '/cloud/references-connectors-sftp',
          },
          {
            name: 'Smartsheet',
            link: '/cloud/references-connectors-smartsheet',
          },
          {
            name: 'Snowflake',
            link: '/cloud/references-connectors-snowflake',
          },
          {
            name: 'Splunk',
            link: '/cloud/references-connectors-splunk',
          },
          {
            name: 'Teradata',
            link: '/cloud/r-teradata-connector',
          },
          {
            name: 'Vertica',
            link: '/cloud/r-vertica-connector',
          },
          {
            name: 'XML',
            link: '/cloud/references-connectors-xml',
          },
          {
            name: 'Zuora',
            link: '/cloud/references-connectors-zuora',
          },
        ],
      },
      {
        name: 'Public API',
        link: '/cloud/references-public-api',
        children: [
          {
            name: 'API v1' ,
            link: '/cloud/references-public-api-v1',
          },
          {
            name: 'API v2 (2023.4.0+)',
            link: '/cloud/references-public-api-v2',
          },
        ],
      },
      {
        name: 'Security',
        link: '/cloud/references-security',
        children: [
          {
            name: 'OAuth for Microsoft Azure Gen1',
            link: '/cloud/references-security-oauth-microsoft-azure-gen1',
          },
          {
            name: 'OAuth for OneDrive',
            link: '/cloud/references-security-oauth-onedrive',
          },
          {
            name: 'OAuth for Sharepoint',
            link: '/cloud/references-security-oauth-sharepoint',
          },
          {
            name: 'OAuth for Smartsheet',
            link: '/cloud/references-security-oauth-smartsheet',
          },
          {
            name: 'Security Roles',
            link: '/cloud/references-security-roles',
          },
        ],
      },
      {
        name: 'Visualizations',
        link: '/cloud/references-visualizations-all',
        children: [
          {
            name: 'Insights over Result Sets',
            link: '/cloud/references-visualizations-insights-over-result-sets',
          },
          {
            name: 'Advanced Map',
            link: '/cloud/references-visualizations-advanced-map',
          },
          {
            name: 'Aggregated Table',
            link: '/cloud/references-visualizations-aggregated-table',
          },
          {
            name: 'Area',
            link: '/cloud/references-visualizations-area',
          },
          {
            name: 'Area Range',
            link: '/cloud/references-visualizations-area-range',
          },
          {
            name: 'Bar',
            link: '/cloud/references-visualizations-bar',
          },
          {
            name: 'Bubble',
            link: '/cloud/references-visualizations-bubble',
          },
          {
            name: 'Bubble Map',
            link: '/cloud/references-visualizations-bubble-map',
          },
          {
            name: 'Column',
            link: '/cloud/references-visualizations-column',
          },
          {
            name: 'Combination',
            link: '/cloud/references-visualizations-combination',
          },
          {
            name: 'Combo Dual Axis',
            link: '/cloud/references-visualizations-combo-dual-axis',
          },
          {
            name: 'Combo',
            link: '/cloud/references-visualizations-combo',
          },
          {
            name: 'Donut',
            link: '/cloud/references-visualizations-donut',
          },
          {
            name: 'Dual X-Axis',
            link: '/cloud/references-visualizations-dual-x-axis',
          },
          {
            name: 'Funnel',
            link: '/cloud/references-visualizations-funnel',
          },
          {
            name: 'Gauge',
            link: '/cloud/references-visualizations-gauge',
          },
          {
            name: 'Heatmap',
            link: '/cloud/references-visualizations-heatmap',
          },
          {
            name: 'KPI',
            link: '/cloud/references-visualizations-kpi',
          },
          {
            name: 'Line',
            link: '/cloud/references-visualizations-line',
          },
          {
            name: 'Line Time Series',
            link: '/cloud/references-visualizations-line-time-series',
          },
          {
            name: 'Listing Table',
            link: '/cloud/references-visualizations-listing-table',
          },
          {
            name: 'Map',
            link: '/cloud/references-visualizations-map',
          },
          {
            name: 'Organizational',
            link: '/cloud/references-visualizations-organizational',
          },
          {
            name: 'Packed Bubble',
            link: '/cloud/references-visualizations-packed-bubble',
          },
          {
            name: 'Percent Area',
            link: '/cloud/references-visualizations-percent-area',
          },
          {
            name: 'Percent Bar',
            link: '/cloud/references-visualizations-percent-bar',
          },
          {
            name: 'Percent Column',
            link: '/cloud/references-visualizations-percent-column',
          },
          {
            name: 'Percent Line',
            link: '/cloud/references-visualizations-percent-line',
          },
          {
            name: 'Pie',
            link: '/cloud/references-visualizations-pie',
          },
          {
            name: 'Pie Donut',
            link: '/cloud/references-visualizations-pie-donut',
          },
          {
            name: 'Pivot Table',
            link: '/cloud/references-visualizations-pivot-table',
          },
          {
            name: 'Pyramid',
            link: '/cloud/references-visualizations-pyramid',
          },
          {
            name: 'Radial Bar',
            link: '/cloud/references-visualizations-radial-bar',
          },
          {
            name: 'Rich Text',
            link: '/cloud/references-visualizations-rich-text',
          },
          {
            name: 'Sankey',
            link: '/cloud/references-visualizations-sankey',
          },
          {
            name: 'Scatter',
            link: '/cloud/references-visualizations-scatter',
          },
          {
            name: 'Shapes',
            link: '/cloud/references-visualizations-shapes',
          },
          {
            name: 'Solid Gauge',
            link: '/cloud/references-visualizations-solid-gauge',
          },
          {
            name: 'Spider',
            link: '/cloud/references-visualizations-spider',
          },
          {
            name: 'Stacked Area',
            link: '/cloud/references-visualizations-stacked-area',
          },
          {
            name: 'Stacked Bar',
            link: '/cloud/references-visualizations-stacked-bar',
          },
          {
            name: 'Stacked Column',
            link: '/cloud/references-visualizations-stacked-column',
          },
          {
            name: 'Stacked Column and Line',
            link: '/cloud/references-visualizations-stacked-column-line',
          },
          {
            name: 'Stacked Line',
            link: '/cloud/references-visualizations-stacked-line',
          },
          {
            name: 'Sunburst',
            link: '/cloud/references-visualizations-sunburst',
          },
          {
            name: 'Tag Cloud',
            link: '/cloud/references-visualizations-tag-cloud',
          },
          {
            name: 'Time Series',
            link: '/cloud/references-visualizations-time-series',
          },
          {
            name: 'Tornado',
            link: '/cloud/references-visualizations-tornado',
          },
          {
            name: 'Treemap',
            link: '/cloud/references-visualizations-treemap',
          },
          {
            name: 'Waterfall',
            link: '/cloud/references-visualizations-waterfall',
          },
        ],
      },
      {
        name: 'SQL Interface',
        link: '/cloud/references-sqli-all',
        children: [
          {
            name: 'SQL Interface',
            link: '/cloud/references-sqli',
          },
          {
            name: 'Configure Spark with SQLi',
            link: '/cloud/spark-config-sqli',
          },
          {
            name: 'Advanced SQL Interface',
            link: '/cloud/references-advanced-sqli',
          },
        ],
      },
      {
        name: 'Other',
        link: '/cloud/references-other',
        children: [
          {
            name: 'Customize Incorta with Themes',
            link: '/cloud/references-customize-incorta-with-themes',
          },
          {
            name: 'Data Consistency and Availability',
            link: '/cloud/references-data-consistency-and-availability',
          },
          {
            name: 'Data Ingestion and Loading',
            link: '/cloud/references-data-ingestion-and-loading',
          },
          {
            name: 'Decimal Data Support',
            link: '/cloud/references-decimal-data-support',
          },
          {
            name: 'Configure Spark for Use with Materialized Views',
            link: '/cloud/spark-config-mv',
          },
          {
            name: 'Configure the SQLi interface',
            link: '/cloud/spark-config-sqli',
          },
          {
            name: 'Null Handling',
            link: '/cloud/references-null-handling',
          },
        ],
      },
    ],
  },
  {
    name: 'Integrations',
    link: '/cloud/integrations-all',
    children: [
      {
        name: 'Microsoft Excel Add-in',
        link: '/cloud/integrations-microsoft-excel-add-in',
      },
      {
        name: 'Microsoft Power BI',
        link: '/cloud/integrations-microsoft-power-bi',
      },
      {
        name: 'Microsoft Teams',
        link: '/cloud/integrations-microsoft-teams',
      },
      {
        name: 'Slack',
        link: '/cloud/integrations-slack',
      },
      {
        name: 'Tableau for SQLi',
        link: '/cloud/integrations-tableau',
      },
      {
        name: 'Tableau for Advanced SQLi',
        link: '/cloud/integrations-tableau-advanced-sqli',
      },
      {
        name: 'XLCubed',
        link: '/cloud/integrations-xlcubed',
      },
    ],
  },
  {
    name: 'Data applications',
    link: '/cloud/blueprints-all',
    children: [
      {
        name: 'Incorta Data Delivery',
        link: '/cloud/blueprints-data-delivery-all',
        children: [
          {
            name: 'Data Delivery for Azure Synapse',
            link: '/cloud/configure-incorta-data-delivery-for-azure-synapse',
          },
          {
            name: 'Data Delivery for Google BigQuery',
            link: '/cloud/configure-incorta-data-delivery-for-bigquery',
          },
          {
            name: 'Data Delivery of Oracle Cloud ERP for Azure Synapse',
            link: '/cloud/blueprints-data-delivery-oracle-cloud-erp-synapse',
          },
          {
            name: 'Data Delivery of Oracle EBS for Azure Synapse',
            link: '/cloud/blueprints-data-delivery-oracle-ebs-synapse',
          },
      ],
      },
        {
        name: 'Oracle Cloud ERP data app',
        link: '/cloud/data-application-oracle-cloud-all',
        children: [
          {
            name: 'Installation and Configuration',
            link: '/cloud/data-application-install-configure-oracle-erp',
          },
          {
            name: 'Analyze Financials and Supply Chain',
            link: '/cloud/data-application-oracle-financial-supply-chain',
          },
          {
            name: 'Analyze Human Capital Management',
            link: '/cloud/data-application-oracle-cloud-hcm',
          },
          {
            name: 'FAQs and Troubleshooting',
            link: '/cloud/data-application-oracle-cloud-faq',
          },

        ],
      },
      {
        name: 'Oracle EBS data app',
        link: '/cloud/blueprints-oracle-ebs-incorta-cloud',
        children: [
          {
            name: 'Analyze Oracle EBS Accounts Payable',
            link: '/cloud/blueprints-oracle-ebs-accounts-payable',
          },
          {
            name: 'Analyze Oracle EBS Accounts Receivable',
            link: '/cloud/blueprints-oracle-ebs-accounts-receivable',
          },
          {
            name: 'Analyze Oracle EBS Fixed Assets',
            link: '/cloud/blueprints-oracle-ebs-fixed-assets',
          },
          {
            name: 'Analyze Oracle EBS General Ledger',
            link: '/cloud/blueprints-oracle-ebs-general-ledger',
          },
          {
            name: 'Analyze Oracle EBS Inventory Management',
            link: '/cloud/blueprints-oracle-ebs-inventory-management',
          },
          {
            name: 'Analyze Oracle EBS Order Management',
            link: '/cloud/blueprints-oracle-ebs-order-management',
          },
          {
            name: 'Analyze Oracle EBS Purchasing',
            link: '/cloud/blueprints-oracle-ebs-purchasing',
          },
          {
            name: 'Analyze Oracle EBS Projects',
            link: '/cloud/blueprints-oracle-ebs-projects',
          },
          {
            name: 'Analyze Oracle EBS Human Resources',
            link: '/cloud/blueprints-oracle-ebs-human-resources',
          },
          {
            name: 'Power BI dashboards for Oracle EBS',
            link: '/cloud/blueprints-power-bi-dashboards-for-oracle-ebs',
          },
          {
            name: 'XLCubed Excel plugin dashboards for Oracle EBS',
            link: '/cloud/blueprints-xlcubed-excel-plugin-dashboards-for-oracle-ebs',
          },
        ],
      },
      {
        name: 'Salesforce data app',
        link: '/cloud/blueprints-salesforce-incorta-cloud',
        children: [
          {
            name: 'Analyze Finance',
            link: '/cloud/blueprints-salesforce-finance',
          },
          {
            name: 'Analyze Sales',
            link: '/cloud/blueprints-salesforce-sales',
          },
          {
            name: 'Analyze Marketing',
            link: '/cloud/blueprints-salesforce-marketing',
          },
        ],
      },
      {
        name: 'BlackLine',
        link: '/cloud/data-application-blackline',
        children: [
          {
            name: 'Account Reconciliations for Oracle EBS',
            link: '/cloud/data-application-blackline-oracle-ebs',
          },
          {
            name: 'Account Reconciliations for Oracle Cloud ERP',
            link: '/cloud/blueprints-blackline-account-reconciliations-oracle-erp',
          },
          {
            name: 'Account Reconciliations for SAP ECC',
            link: '/cloud/data-application-blackline-sap-ecc',
          },
          {
            name: 'Account Reconciliations for SAP S/4HANA',
            link: '/cloud/data-application-blackline-sap-s4-hana',
          },
        ],
      },
      {
        name: 'XLCubed Excel plugin dashboards for SAP ECC',
        link: '/cloud/data-application-xlcubed-sap-ecc',
      },
      {
        name: 'NetSuite data app',
        link: '/cloud/blueprints-netsuite-incorta-cloud',
      },
      {
        name: 'SAP S/4HANA Financials and Supply Chain data app',
        link: '/cloud/data-application-sap-s4hana-financials-and-supply-chain',
      },
      {
        name: 'Data app Data Wizard',
        link: '/cloud/blueprints-data-wizard',
      },
    ],
  },
  {
    name: 'Releases',
    link: '/cloud/releases',
    children: [
      {
        name: '2024.1.x',
        link: '/cloud/release-notes-2024-1-0',
      },
      {
        name: "What's New 2024.1.x",
        link: '/cloud/whats-new-2024-1-0',
      },
      {
        name: '2024 Known Issues',
        link: '/cloud/2024-known-issues',
      },
      {
        name: 'Cloud Platform Release Notes',
        link: '/cloud/2024-cloud-release-notes',
      },
      {
        name: '2023 Releases',
        link: '/cloud/2023-releases',
        children: [
          {
            name: '2023 Cloud Platform Release Notes',
            link: '/cloud/2023-cloud-release-notes',
          },
          {
            name: '2023.7.x',
            link: '/cloud/release-notes-2023-7-0',
          },
          {
            name: "What's New in 2023.7.0",
            link: '/cloud/whats-new-2023-7-0',
          },
          {
            name: '2023.4.x',
            link: '/cloud/release-notes-2023-4-0',
          },
          {
            name: "What's New in 2023.4.0",
            link: '/cloud/whats-new-2023-4-0',
          },
          {
            name: '2023.1.x',
            link: '/cloud/release-notes-2023-1-0',
          },
          {
            name: "What's New in 2023.1.0",
            link: '/cloud/whats-new-2023-1-0',
          },
          {
            name: '2023 Known Issues',
            link: '/cloud/2023-known-issues',
          },
    ],},
      {
        name: '2022 Releases',
        link: '/cloud/2022-releases',
        children: [
          {
            name: '2022.12.0',
            link: '/cloud/release-notes-2022-12-0',
          },
          {
            name: "What's New in 2022.12.0",
            link: '/cloud/whats-new-2022-12-0',
          },
          {
            name: '2022.11.x',
            link: '/cloud/release-notes-2022-11-0',
          },
          {
            name: "What's New in 2022.11.0",
            link: '/cloud/whats-new-2022-11-0',
          },
          {
              name: '2022.10.0',
              link: '/cloud/release-notes-2022-10-0',
            },
          {
            name: "What's New in 2022.10.0",
            link: '/cloud/whats-new-2022-10-0',
          },
          {
            name: '2022.9.x',
            link: '/cloud/release-notes-2022-9-0',
          },
          {
            name: "What's New in 2022.9.0",
            link: '/cloud/whats-new-2022-9-0',
          },
          {
            name: '2022.8.0',
            link: '/cloud/release-notes-2022-8-0',
          },
          {
            name: "What's New in 2022.8.0",
            link: '/cloud/whats-new-2022-8-0',
          },
          {
            name: '2022.7.0',
            link: '/cloud/release-notes-2022-7-0',
          },
          {
            name: '2022.6.0',
            link: '/cloud/release-notes-2022-6-0',
          },
          {
            name: '2022.5.1',
            link: '/cloud/release-notes-2022-5-1',
          },
          {
            name: '2022.5.0',
            link: '/cloud/release-notes-2022-5-0',
          },
          {
            name: '2022.4.0',
            link: '/cloud/release-notes-2022-4-0',
          },
          {
            name: '2022.3.0',
            link: '/cloud/release-notes-2022-3-0',
          },
          {
            name: '2022.2.0',
            link: '/cloud/release-notes-2022-2-0',
          },
          {
            name: '2022.1.0',
            link: '/cloud/release-notes-2022-1-0',
          },
          {
            name: '2022 Known Issues',
            link: '/cloud/2022-known-issues',
          },
        ],
      },
      {
        name: '2021 Releases',
        link: '/cloud/2021-releases',
        children: [
          {
            name: '2021.4.3',
            link: '/cloud/release-notes-2021-4-3',
          },
          {
            name: '2021.4.2',
            link: '/cloud/release-notes-2021-4-2',
          },
          {
            name: '2021.4.1',
            link: '/cloud/release-notes-2021-4-1',
          },
          {
            name: '2021.3.3',
            link: '/cloud/release-notes-2021-3-3',
          },
          {
            name: '2021.3.2',
            link: '/cloud/release-notes-2021-3-2',
          },
          {
            name: '2021.3.1',
            link: '/cloud/release-notes-2021-3-1',
          },
        ],
      },
    ],
  },
];

module.exports = tocCloud;
